/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer";

const Layout = ({ children, path, isHome = false, mt = false, mb = false, secondMain = <></> }) => {
  const {site, allWpMenu} = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      allWpMenu(filter: {slug: {eq: "menu"}}) {
        nodes {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    }
  `)

  const title = site.siteMetadata?.title || `Title`
  const description = site.siteMetadata?.description || `Description`
  const menu = allWpMenu.nodes[0].menuItems.nodes || []

  return (
    <>
      <Header
        siteTitle={title}
        siteDescription={description}
        isHome={isHome}
        menu={menu}
        activeLink={`/${path.split('/')[1]}`}
      />
      <div className={`main ${mt && 'mt'} ${mb && 'mb'}`}>
        <main>{children}</main>
      </div>
      {secondMain}
      <Footer title={title} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
