import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.svg"
import logoWhite from "../../assets/images/logo-white.svg"
import logoMin from "../../assets/images/logo-mini.svg"

const Header = ({ isHome, siteDescription, siteTitle, menu, activeLink }) => (
  <header>
    <div className={`${isHome ? 'home' : 'notHome'} header container`}>
      <Link to={'/'} className={'logo__link'}>
        <img className={'logo'} src={isHome ? logo : logoMin} alt={siteTitle} />
      </Link>
      <div className={'menu'}>
        {isHome && <h1 className={'description'}>{siteDescription}</h1>}

        <input type={'checkbox'} id={'toggle'} className={'toggle-nav'}/>
        <label className={'toggle-nav'} htmlFor={'toggle'}>
          <div className="bar" />
          <div className="bar" />
          <div className="bar" />
        </label>

        <nav>
          <img className={'logo__responsive d-lg-none'} src={logoWhite} alt={siteTitle}  />
          <ul>
            {menu.map((item, index) => (
              <li key={index} className={`${item.url === activeLink && 'selected'}`}>
                <Link to={item.url} className={'menu__link'}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  isHome: false,
  siteTitle: ``,
  siteDescription: ``,
  menu: []
}

export default Header
