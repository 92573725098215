import React from "react"

const Footer = ({title}) => (
  <footer>
    <div className={'container d-flex flex-column flex-lg-row'}>
      <div className={'col-6 left__col'}>
        <div className={'col-8'}>
          <h5>{title}</h5>
          <p>Nous représentons des éditeurs ainsi que des auteurs dans le monde entier dans les domaines de la fiction, de la non-fiction et de la jeunesse.</p>
          <p>Established in 1982, our agency represents a wide range of publishing houses and authors in fiction, non-fiction and children’s literature.</p>
        </div>
      </div>
      <div className={'col-6'}>
        <p className={'social'}>
          <a href={'https://www.linkedin.com/in/cristina-prepelita-chiarasini-66264185/'} target={'_blank'}><i className={"fab fa-linkedin"} /></a>&nbsp;&nbsp;
          <a href={'https://www.instagram.com/agencecgr/'} target={'_blank'}><i className={"fab fa-instagram"} /></a>
        </p>
        <p className={'address'}>
          <span className={'d-block d-md-inline'}>Agence littéraire CGR</span><span className={'d-none d-md-inline'}>&nbsp;•&nbsp;</span>
          <span className={'d-block d-md-inline'}>36, rue Cler, Paris, 75007</span><span className={'d-none d-md-inline'}>&nbsp;•&nbsp;</span>
          <span className={'d-block d-md-inline'}>+ 33 1 45 66 71 11</span><span className={'d-none d-md-inline'}>&nbsp;</span>
          <span className={'d-block d-md-inline'}>contact[at]agencelitteraire-cgr.com</span>
        </p>
        <p>Design : <a href={'https://marieblsn.com/'} target={'_blank'}>Marie Balsan</a> <br /> Development : <a href={'https://guillemfondin.fr/'} target={'_blank'}>Guillem Fondin</a></p>
        <p>Copyright © Agence littéraire CGR - Tous droits réservés - <a href={'/credits'}>Crédits</a> - <a href={'/mentions-legales'}>Mentions légales</a></p>
      </div>
    </div>
  </footer>
)

export default Footer
